<template>
  <USelect
    v-model="currentMenuLocale"
    :items="localesMenu"
    :ui="{ base: 'w-full' }"
  />
</template>

<script setup lang="ts">
const { locales, locale, setLocale, loadLocaleMessages } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const ionRouter = useIonRouter()

const currentMenuLocale = ref(locale)
const localesMenu = computed(() => locales.value.map(locale => ({
  value: locale.code,
  label: locale.name,
})))

watch(currentMenuLocale, async (newLocale) => {
  console.log(newLocale)
  await loadLocaleMessages(newLocale.value)
  await setLocale(newLocale.value)
  // TODO: navigateTo - history and swipe works, but with push animation. ionRouter doesnt work yet
  // ionRouter.navigate(switchLocalePath(newLocale), 'none', 'replace')
  navigateTo(switchLocalePath(newLocale.value), { replace: true })
})
</script>
