<template>
  <footer
    v-if="!Capacitor.isNativePlatform()"
    class="bg-white"
  >
    <UContainer class="pt-16 sm:pt-24">
      <div class="grid grid-cols-12 gap-y-8">
        <div class="col-span-12 gap-y-2 pr-0 sm:col-span-5 sm:pr-8">
          <NuxtImg
            class="h-20"
            src="/upfan.svg"
            alt="Upfan"
          />
          <p class="my-4 text-gray-600">
            {{ t('footer.description') }}
          </p>
          <div class="mt-4 flex gap-x-3">
            <ULink
              v-for="(social, index) in socialLinks"
              :key="index"
              :to="social.link"
              @click.prevent="Browser.open({ url: social.link })"
            >
              <span class="sr-only">{{ social.name }}</span>
              <UIcon
                :name="social.icon"
                class="size-6"
              />
            </ULink>
          </div>
          <LanguageSelector class="mt-4" />
        </div>
        <div class="col-span-12 grid grid-cols-2 sm:col-span-5 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
              {{ t('footer.links.label') }}
            </h3>
            <ul
              role="list"
              class="mt-3 space-y-3"
            >
              <li
                v-for="item in navigation.links"
                :key="item.name"
              >
                <ULink
                  exact
                  :to="localePath(item.href)"
                  class="text-sm leading-6"
                  @click.prevent="ionRouter.navigate(localePath(item.href), item.href.startsWith('index') ? 'none' : 'forward', 'push')"
                >{{ item.name }}</ULink>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
              {{ t('footer.legal.label') }}
            </h3>
            <ul
              role="list"
              class="mt-3 space-y-3"
            >
              <li
                v-for="item in navigation.legal"
                :key="item.name"
              >
                <ULink
                  :to="localePath(item.href)"
                  class="text-sm leading-6"
                  @click.prevent="item.onClick"
                >{{ item.name }}</ULink>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2">
          <h3 class="text-sm font-semibold leading-6 text-gray-900">
            {{ t('footer.partnership.label') }}
          </h3>
          <UButton
            block
            :ui="{ base: 'justify-center' }"
            to="mailto:info@upfan.io"
            class="mt-3"
            size="lg"
            @click.prevent="Browser.open({ url: 'mailto:info@upfan.io' })"
          >
            {{ t('footer.partnership.contact-us.text') }}
          </UButton>
        </div>
      </div>
      <USeparator class="mt-16" />
      <h3 class="py-4 text-center text-sm text-gray-600">
        &copy; {{ DateTime.now().year }} {{ t('footer.rights-reserved') }}
      </h3>
    </UContainer>
  </footer>
</template>

<script setup>
import { DateTime } from 'luxon'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

const localePath = useLocalePath()
const { t } = useI18n()
const ionRouter = useIonRouter()

const socialLinks = ref([
  // {
  //   icon: 'i-simple-icons-facebook',
  //   name: t('footer.socials.facebook.sr-only'),
  //   link: t('footer.socials.facebook.link'),
  // },
  // {
  //   icon: 'i-simple-icons-instagram',
  //   name: t('footer.socials.instagram.sr-only'),
  //   link: t('footer.socials.instagram.link'),
  // },
  // {
  //   icon: 'i-simple-icons-x',
  //   name: t('footer.socials.twitter.sr-only'),
  //   link: t('footer.socials.twitter.link'),
  // },
  {
    icon: 'i-simple-icons-linkedin',
    // name: t('footer.socials.linkedin.sr-only'),
    // link: t('footer.socials.linkedin.link'),
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/upfan/',
  },
])

const navigation = computed(() => ({
  links: [
    { name: t('footer.links.home'), href: 'index' },
    { name: t('footer.links.events'), href: 'index-events' },
    { name: t('footer.links.tickets'), href: 'index-tickets' },
    { name: t('footer.links.about-us'), href: 'about' },
    { name: t('footer.links.profile'), href: 'index-profile' },
  ],
  legal: [
    { name: t('footer.legal.terms'), blank: false, href: 'terms', onClick: () => ionRouter.navigate(localePath('terms'), 'forward', 'push') },
    { name: t('footer.legal.gdpr'), blank: true, href: '/gdpr.pdf', onClick: () => Browser.open({ url: '/gdpr.pdf' }) },
  ],
}))
</script>
