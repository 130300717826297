export default {
  "slots": {
    "root": "flex items-center align-center text-center",
    "border": "",
    "container": "font-medium text-gray-700 dark:text-gray-200 flex",
    "icon": "shrink-0 size-5",
    "avatar": "shrink-0",
    "avatarSize": "2xs",
    "label": "text-sm"
  },
  "variants": {
    "color": {
      "primary": {
        "border": "border-primary-500 dark:border-primary-400"
      },
      "error": {
        "border": "border-error-500 dark:border-error-400"
      },
      "red": {
        "border": "border-red-500 dark:border-red-400"
      },
      "orange": {
        "border": "border-orange-500 dark:border-orange-400"
      },
      "amber": {
        "border": "border-amber-500 dark:border-amber-400"
      },
      "yellow": {
        "border": "border-yellow-500 dark:border-yellow-400"
      },
      "lime": {
        "border": "border-lime-500 dark:border-lime-400"
      },
      "green": {
        "border": "border-green-500 dark:border-green-400"
      },
      "emerald": {
        "border": "border-emerald-500 dark:border-emerald-400"
      },
      "teal": {
        "border": "border-teal-500 dark:border-teal-400"
      },
      "cyan": {
        "border": "border-cyan-500 dark:border-cyan-400"
      },
      "sky": {
        "border": "border-sky-500 dark:border-sky-400"
      },
      "blue": {
        "border": "border-blue-500 dark:border-blue-400"
      },
      "indigo": {
        "border": "border-indigo-500 dark:border-indigo-400"
      },
      "violet": {
        "border": "border-violet-500 dark:border-violet-400"
      },
      "purple": {
        "border": "border-purple-500 dark:border-purple-400"
      },
      "fuchsia": {
        "border": "border-fuchsia-500 dark:border-fuchsia-400"
      },
      "pink": {
        "border": "border-pink-500 dark:border-pink-400"
      },
      "rose": {
        "border": "border-rose-500 dark:border-rose-400"
      },
      "gray": {
        "border": "border-gray-200 dark:border-gray-800"
      }
    },
    "orientation": {
      "horizontal": {
        "root": "w-full flex-row",
        "border": "w-full",
        "container": "mx-3 whitespace-nowrap"
      },
      "vertical": {
        "root": "h-full flex-col",
        "border": "h-full",
        "container": "my-2"
      }
    },
    "size": {
      "xs": "",
      "sm": "",
      "md": "",
      "lg": "",
      "xl": ""
    },
    "type": {
      "solid": {
        "border": "border-solid"
      },
      "dashed": {
        "border": "border-dashed"
      },
      "dotted": {
        "border": "border-dotted"
      }
    }
  },
  "compoundVariants": [
    {
      "orientation": "horizontal" as const,
      "size": "xs" as const,
      "class": {
        "border": "border-t"
      }
    },
    {
      "orientation": "horizontal" as const,
      "size": "sm" as const,
      "class": {
        "border": "border-t-[2px]"
      }
    },
    {
      "orientation": "horizontal" as const,
      "size": "md" as const,
      "class": {
        "border": "border-t-[3px]"
      }
    },
    {
      "orientation": "horizontal" as const,
      "size": "lg" as const,
      "class": {
        "border": "border-t-[4px]"
      }
    },
    {
      "orientation": "horizontal" as const,
      "size": "xl" as const,
      "class": {
        "border": "border-t-[5px]"
      }
    },
    {
      "orientation": "vertical" as const,
      "size": "xs" as const,
      "class": {
        "border": "border-s"
      }
    },
    {
      "orientation": "vertical" as const,
      "size": "sm" as const,
      "class": {
        "border": "border-s-[2px]"
      }
    },
    {
      "orientation": "vertical" as const,
      "size": "md" as const,
      "class": {
        "border": "border-s-[3px]"
      }
    },
    {
      "orientation": "vertical" as const,
      "size": "lg" as const,
      "class": {
        "border": "border-s-[4px]"
      }
    },
    {
      "orientation": "vertical" as const,
      "size": "xl" as const,
      "class": {
        "border": "border-s-[5px]"
      }
    }
  ],
  "defaultVariants": {
    "color": "gray" as const,
    "size": "xs" as const,
    "type": "solid" as const
  }
}